<template>
  <div class="flex items-center justify-between bg-tp-black border-2 rounded-xl p-4" :class="{'border-green-500' : roundInfo.event_type==='accepted', 'border-red-500' : roundInfo.event_type==='canceled'}">
    <div class="flex flex-col gap-2">
      <div class="flex gap-2 items-center">
        <div class="flex items-center bg-tp-black py-1 w-max border-2 border-red-500 rounded-lg px-4" v-if="roundInfo.event_type==='canceled'">
          <p class="text-xs">Rechazada</p>
        </div>
        <div class="flex items-center bg-tp-black py-1 w-max border-2 border-green-500 rounded-lg px-4" v-if="roundInfo.event_type==='accepted'">
          <p class="text-xs">Ganadora</p>
        </div>
        <p class="font-medium text-xs">{{ formatTimestamp(roundInfo.timestamp) }}</p>
      </div>
      <div class="flex flex-col text-tp-yellow">
        <p>{{ roundInfo.songInfo.songName }}</p>
        <p>{{ roundInfo.songInfo.artist }}</p>
      </div>
    </div>
    <div class="flex justify-center items-center border-2 p-1 h-full rounded-lg px-3" :class="{'border-green-500' : roundInfo.event_type==='accepted', 'border-red-500' : roundInfo.event_type==='canceled'}">
        <p>{{ roundInfo.amount / 100 }}€</p>
    </div>
  </div>
  
</template>
  
  <script>

  export default {
    name: 'DJRoundInfo',
    props: {
      index: Number,
      roundInfo: Object
    },
    setup() {

      const formatTimestamp = (timestamp) => {
        const date = new Date(Math.floor(timestamp) * 1000);
        return date.toLocaleString('es-ES', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false // Usa formato de 24 horas
        });
      };
      
      return {
        formatTimestamp
      }
    }
  }
  
  
  </script>
  