<template> 
    <div v-if="!isLoading" class="flex flex-col items-center gap-10 pb-10">
        <NavBar />
        <div class="flex flex-col gap-8 w-full">
            <div class="flex flex-col items-center gap-4 px-4">
                <DJHomeOption @click="handleRedirect('my-profile')" text="MI PERFIL" :balance="balance"/>
                <DJHomeOption v-if="!existsActiveSession" @click="handleRedirect('create-session')" text="CREAR SESIÓN" :disabled="cantCreateSession"/>
                <DJActiveSessionOption v-else :sessionsInfo="sessionsInfo" @click="handleRedirect('create-session')" text="SESIÓN ACTIVA" />
            </div>
            <button
                class="bg-tp-yellow p-3 px-8 rounded-xl mx-auto" 
                @click="userLogout">
                <p class="text-tp-black font-bold">Salir</p>
            </button>
        </div>
        <DJCreateSessionModal
        v-show="isCreateSessionModalVisible" 
        @onCloseModal="closeCreateSessionModal" 
        />
    </div>
</template>

<script>
import NavBar from '@/components/Common/NavBar.vue';
import DJHomeOption from '@/components/DJ/DJHomeOption.vue';
import DJCreateSessionModal from '@/components/DJ/DJCreateSessionModal.vue';
import DJActiveSessionOption from '@/components/DJ/DJActiveSessionOption.vue';
import createLoungeService from '@/service/loungeService';
import { getSessionsInfo, getDJPrivateInfo, logout } from '@/service/djService';
import { useRouter} from 'vue-router';
import { ref, onMounted } from 'vue';
export default {
    name: 'DJHome',
    components: {
        NavBar,
        DJHomeOption,
        DJCreateSessionModal,
        DJActiveSessionOption
    },
    setup() {
        const isLoading = ref(true);
        const isCreateSessionModalVisible = ref(false);
        const cantCreateSession = ref(null);
        const existsActiveSession = ref(false);
        const sessionsInfo = ref(null);
        const profileData = ref(null);
        const balance = ref(0);
        const { fetchLoungeDetails } = createLoungeService();

        const router = useRouter();

        const userLogout = () => {
            logout();
            router.push('/dj/login');
        };

        const handleRedirect = async (redirection) => {
            if(redirection == 'my-profile'){
                router.push('/dj/private-area');
            } else if(redirection == 'create-session'){
                if (existsActiveSession.value){
                    const loungeId = sessionsInfo.value.current_session.loungeData.hexID
                    const details = await fetchLoungeDetails(loungeId);
                    console.log(details)
                    if (details.public.ranking === null && details.public.status === 'LOADING'){
                        router.push(`/dj/create-ranking`);
                    } else{
                        router.push(`/dj/active-session/${loungeId}/ranking`);
                    }
                    
                } else {
                    isCreateSessionModalVisible.value = true;
                }
            }
        };

        const closeCreateSessionModal = () => {
            isCreateSessionModalVisible.value = false
        };

        onMounted(async () => {
            isLoading.value = true; // Iniciar la carga
            try {
                sessionsInfo.value = await getSessionsInfo();
                if (sessionsInfo.value.current_session !== null) {
                    existsActiveSession.value = true;
                }
                profileData.value = await getDJPrivateInfo();
                balance.value = profileData.value.saldo.toFixed(2);
                const role = Number(profileData.value.role);
                cantCreateSession.value = !(role === 0 || role === 1);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                isLoading.value = false;
            }
        });
    
        return{
            userLogout,
            handleRedirect,
            closeCreateSessionModal,
            isCreateSessionModalVisible,
            cantCreateSession,
            balance,
            sessionsInfo,
            existsActiveSession,
            isLoading
        }
    }
  }
</script>