<template>
  <div class="relative">
  <NavBar />
  <DJActionButton class="absolute xxs:top-24 top-20 left-8" :image="arrowBack" @click="handleBack"/>
  <div class="flex flex-col gap-8 px-8 py-4">
    <div class="flex flex-col gap-6">
      <p class="font-extrabold text-2xl text-center">Detalles de la sesión <br></p>
      <div class="flex gap-6 bg-tp-black text-tp-yellow border-2 border-tp-yellow rounded-xl p-4">
        <div class="flex items-center justify-center border-2 border-tp-yellow p-4 rounded-lg">
          <img :src=wallet alt="" class="w-8 h-8">
        </div>
        <div class="flex flex-col justify-center" v-if="sessionDetails">
          <p><strong>Fecha:</strong> {{ formatTimestamp(sessionDetails.start_timestamp) }}</p>
          <p><strong>Total generado:</strong>   {{ sessionDetails.dinero_generado }} €</p>          
        </div>
      </div>
    </div> 
    <div class="flex flex-col gap-6">
      <p class="font-extrabold text-xl">Canciones aceptadas/rechazadas</p>
      <div class="flex flex-col gap-4" v-if="eventDetails">
        <DJRoundInfo v-for="(round, index) in eventDetails" :key="index" :roundInfo="round" :index="index+1" />
      </div>
    </div>
  </div>
</div>
</template>
  
  <script>
  import NavBar from '@/components/Common/NavBar.vue';
  import DJRoundInfo from '@/components/DJ/DJRoundInfo.vue';
  import { ref, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import wallet from '@/assets/images/wallet.png';
  import { getPastSessionInfo } from '../../service/djSessionsService';
  import DJActionButton from '@/components/DJ/DJActionButton.vue';
  import arrowBack from '@/assets/images/arrow-back.png';
  
  export default {
    name: 'SessionDetails',
    components: {
      NavBar,
      DJRoundInfo,
      DJActionButton
    },
    setup() {
      const route = useRoute();

      const router = useRouter();
  
      const sessionDetails = ref(null);

      const handleBack = () => {
        router.push('/dj/past-sessions');
      };

      const formatTimestamp = (timestamp) => {
        const date = new Date(Math.floor(timestamp) * 1000);
        return date.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });

      }

      const eventDetails = ref(null);

      onMounted(async () => {
        try {
          const sessionInfo = await getPastSessionInfo(route.params.id);
          sessionDetails.value = sessionInfo.session_data;
          eventDetails.value = sessionInfo.events;
          console.log(sessionDetails.value)
          console.log(eventDetails.value)
        } catch (error) {
          console.error('Error fetching session details:', error);
        }
      });


      return {
        sessionDetails,
        wallet,
        formatTimestamp,
        eventDetails,
        arrowBack,
        handleBack
      }
    }
  }
  </script>
  
  