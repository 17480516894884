import createApiService from './apiService';

const apiService = createApiService();

async function getPastSessionInfo(sessionId) {
    try {
        const response = await apiService.get(`/private/sessions/${sessionId}`);
    
        return response.data;
    
      } catch (error) {
        console.error('Error getting Sessions info:', error);
        throw error;
      }
 
}

export {getPastSessionInfo}