<template>
  <div class="h-screen flex flex-col">
    <div class="relative bg-tp-black mb-8 px-3">
      <div class="relative flex justify-center w-full py-2 items-center">
        <p class="text-tp-yellow text-3xl xs:text-4xl font-bold pt-2">TuParty</p>
        <img class="w-6 absolute top-6 right-5" :src="info" @click="openPopup" alt="">
      </div>
      <div class="flex justify-center gap-1 w-full">
        <div class="flex flex-col items-center gap-2 mt-10">
          <img :src="trophy2" alt="" class="w-8">
          <div class="w-16 bg-tp-black rounded-full border-2 border-tp-yellow">
            <p class="font-bold text-center text-sm py-1">{{ rankingSongs[1]?.votes || 0 }}</p>
          </div>
          <PodiumSong 
            :image="rankingSongs[1]?.songId ? rankingSongs[1]?.image: defaultSongImage" 
            :artist="rankingSongs[1]?.songId ? rankingSongs[1]?.artist : '¡Toca aquí!'" 
            :song="rankingSongs[1]?.songId ? rankingSongs[1]?.songName : 'Tu canción'" 
            :showBoostButton="true" 
            :position="2" 
            @onBoostClick="rankingSongs[1]?.songId ? openRankingModal(rankingSongs[1]?.songId, rankingSongs[1]?.songName, rankingSongs[1]?.artist) : handleAddSong()" 
            :class="{'z-30': !modalVisible && !popupOpen}"
            :isDJ = "false"
          />
        </div>

        <div class="flex flex-col items-center gap-2">
          <div class="flex flex-col items-center gap-2">
            <img :src="trophy1" alt="" class="w-8">
            <div class="w-16 bg-tp-black rounded-full border-2 border-tp-yellow">
              <p class="font-bold text-center text-sm py-1">{{ rankingSongs[0]?.votes || 0 }}</p>
            </div>
            <PodiumSong 
              :image="rankingSongs[0]?.songId ? rankingSongs[0]?.image : defaultSongImage" 
              :artist="rankingSongs[0]?.songId ? rankingSongs[0]?.artist : '¡Toca aquí!'" 
              :song="rankingSongs[0]?.songId ? rankingSongs[0]?.songName : 'Tu canción'" 
              :showBoostButton="true" 
              :position="1" 
              @onBoostClick="rankingSongs[0]?.songId ? openRankingModal(rankingSongs[0]?.songId, rankingSongs[0]?.songName, rankingSongs[0]?.artist) : handleAddSong()" 
              :class="{'z-20': !modalVisible && !popupOpen}"
              :isDJ = "false"
            />
          </div>
        </div>
        <div class="flex flex-col items-center gap-2 mt-10">
          <img :src="trophy3" alt="" class="w-8">
          <div class="w-16 bg-tp-black rounded-full border-2 border-tp-yellow">
            <p class="font-bold text-center text-sm py-1">{{ rankingSongs[2]?.votes || 0 }}</p>
          </div>
          <PodiumSong 
            :image="rankingSongs[2]?.songId ? rankingSongs[2]?.image : defaultSongImage" 
            :artist="rankingSongs[2]?.songId ? rankingSongs[2]?.artist : '¡Toca aquí!'" 
            :song="rankingSongs[2]?.songId ? rankingSongs[2]?.songName : 'Tu canción'" 
            :showBoostButton="true" 
            :position="3" 
            @onBoostClick="rankingSongs[2]?.songId ? openRankingModal(rankingSongs[2]?.songId, rankingSongs[2]?.songName, rankingSongs[2]?.artist) : handleAddSong()" 
            :class="{'z-20': !modalVisible && !popupOpen}"
            :isDJ="false"
          />
        </div>
      </div>
      <InfoPopup :paragraphs="paragraphs" :isOpen="popupOpen" @closePopup="closePopup"/>
      <ConfirmFreeVoteModal
        v-if="confirmModalVisible" 
        @onCloseModal="handleCloseConfirmModal" 
        :class="[{'transition-opacity duration-300 opacity-100 z-30': confirmModalVisible}, {'opacity-0': !confirmModalVisible}]"
      />
      <ConfirmFreeVoteModal
        v-if="confirmPromoCodeModal" 
        @onCloseModal="handleCloseConfirmModal" 
        :promocode="true"
        :class="[{'transition-opacity duration-300 opacity-100 z-30': confirmPromoCodeModal}, {'opacity-0': !confirmPromoCodeModal}]"
      />
      <Transition name="modal-fade">
        <RankingBoostModal
          v-if="modalVisible" 
          @onCloseModal="handleCloseModal" 
          @onClickFreeVote="handleFreeVote" 
          @onClickBoost="handleBoost"
          @onClickPromocode="handlePromocode"
          :delayedFreeBoost="delayedFreeBoost" 
          :class="[{'transition-opacity duration-300 opacity-100 z-30': modalVisible}, {'opacity-0': !modalVisible}]"
          :availableFreeBoost="availableFreeBoost"
          :songSelectedInfo="songSelectedInfo"
        />
      </Transition>
      <Transition name="modal-fade">
        <AddVotesModal 
          v-if="boostModalVisible"
          :votePlans="votePlans" 
          @submitVotePlan="handlePayment" 
          @onCloseModal="handleCloseBoostModal" 
          :class="[{'transition-opacity duration-300 opacity-100 z-30': boostModalVisible}, {'opacity-0': !boostModalVisible}]"
        />
      </Transition>
      <GenericInfoModal 
        v-if="cantAddSongModalVisible"
        :title="'No da tiempo'"
        :message="'Con menos de un minuto restante, al DJ no le daría tiempo a preparar tu canción'"
        :buttonText="'Entendido'"
        @onCloseModal="handleCloseCantAddSongModal" 
      />
      <Timer class="absolute -bottom-6 left-1/2 transform -translate-x-1/2" v-if="!timerIsNull" :initialTime="timeLeft" />

    </div>
    <div class="flex-1 flex flex-col gap-4 items-center bg-tp-black rounded-t-3xl py-6 px-6 yellow-shadow">
      <BoostWarning 
        :alreadyHasSong="false"
      />
      <RankingSong
        :position="null" 
        :image="lens" 
        song="Tu canción" 
        artist="¡Toca aquí!"
        :addSong=true
        @addSong="handleAddSong"
      />
      <RankingSong 
        v-for="(rankingSong, index) in additionalRankingSongs" 
        :key="index" 
        :position="index + 4" 
        :image="rankingSong.image" 
        :song="rankingSong.songName" 
        :artist="rankingSong.artist"
        :votes="rankingSong.votes" 
        :songNotSelectedYet = "true"
        @addSong="handleAddSong" 
        @onBoostClick="openRankingModal(rankingSong.songId, rankingSong.songName, rankingSong.artist)"
      />
      <GenericInfoModal 
        v-if="promocodeFailedModal"
        :title="titlePromocodeFail"
        :message="messagePromocodeFail"
        :buttonText="'Entendido'"
        @onCloseModal="handleClosePromocodeFailedModal" 
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, defineAsyncComponent, computed } from 'vue';
const Timer = defineAsyncComponent(() => import('@/components/Common/Timer.vue'));
import PodiumSong from '@/components/Ranking/PodiumSong.vue';
import RankingSong from '@/components/Ranking/RankingSong.vue';
const InfoPopup = defineAsyncComponent(() => import('@/components/Common/InfoPopup.vue'));
import BoostWarning from '@/components/Ranking/BoostWarning.vue';
import RankingBoostModal from '@/components/Ranking/RankingBoostModal.vue';
import AddVotesModal from '@/components/Common/AddVotesModal.vue';
const ConfirmFreeVoteModal = defineAsyncComponent(() => import('@/components/Ranking/ConfirmFreeVoteModal.vue'));
const GenericInfoModal = defineAsyncComponent(() => import('../components/Common/GenericInfoModal.vue'));
import lens from '@/assets/images/lupa.png';
import logo from '@/assets/images/inverted-logo.png';
import info from '@/assets/images/info-icon.png';
import trophy1 from '@/assets/images/trophy-1-outlined.png';
import trophy2 from '@/assets/images/trophy-2-outlined.png';
import trophy3 from '@/assets/images/trophy-3-outlined.png';
import user from '@/assets/images/user-icon.png';
import defaultSongImage from '@/assets/images/question-mark.png';
import votingService from '../service/votingService';
import { useRoute, useRouter } from 'vue-router';
import { useVotePlans } from '@/composables/votePlans.js';


export default {
  name: 'Ranking',
  components: {
    Timer,
    PodiumSong,
    RankingSong,
    InfoPopup,
    BoostWarning,
    RankingBoostModal,
    ConfirmFreeVoteModal,
    AddVotesModal,
    GenericInfoModal,
  },
  props: {
    loungeDetails: Object
  },
  emits: ['addSong', 'payment', 'onBoostClick', 'onClickBoost'],
  setup(props) {
    const popupOpen = ref(false);
    const modalVisible = ref(false);
    const confirmModalVisible = ref(false);
    const boostModalVisible = ref(false);
    const songSelected = ref(null);
    const songSelectedInfo = ref(null);
    const detailsLoaded = ref(false);
    const details = ref(null);
    const rankingSongs = ref([]);
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeVotingService = votingService(loungeId);
    const timeLeft = ref(null);
    const router = useRouter();
    const availableFreeBoost = ref (null);
    const timerIsNull = ref (true);
    const cantAddSongModalVisible = ref(false);
    const additionalRankingSongs = computed(() => rankingSongs.value.slice(3));
    const confirmPromoCodeModal = ref (false);
    const promocodeFailedModal = ref(false);
    const messagePromocodeFail = ref(null);
    const titlePromocodeFail = ref(null);
    const delayedFreeBoost = ref(true);

  
    const updateRanking = async (songsData) => {
      rankingSongs.value = songsData.map(song => {
        return {
          ...song.songInfo,
          votes: song.votos
        };
      })
    };
    onMounted(() => {
      if (props.loungeDetails && props.loungeDetails.public && props.loungeDetails.public.ranking) {
        const songsData = Object.values(props.loungeDetails.public.ranking.songs);
        updateRanking(songsData);
        availableFreeBoost.value = props.loungeDetails.private.availableFreeBoost;
        delayedFreeBoost.value = props.loungeDetails.private.delayedFreeBoot;
        if (props.loungeDetails.public.ranking.timeLeft === null){
          timerIsNull.value=true;
        }
        else{
          timerIsNull.value=false;
          timeLeft.value = props.loungeDetails.public.ranking.timeLeft
        }
        detailsLoaded.value=true
      }
    });

    watch(() => props.loungeDetails, (newDetails) => {
      if (newDetails && newDetails.public && newDetails.public.ranking) {
        details.value = newDetails;
        const songsData = Object.values(details.value.public.ranking.songs);

        availableFreeBoost.value = newDetails.private.availableFreeBoost;

        delayedFreeBoost.value = newDetails.private.delayedFreeBoot;

        if (songsData.length > 0) {
          updateRanking(songsData);
        }
        if (newDetails.public.ranking.timeLeft === null){
          timerIsNull.value=true;
        }
        else{
          timerIsNull.value=false;
          timeLeft.value = newDetails.public.ranking.timeLeft;
        }
      }
    }, { deep: true });

    const openPopup = () => {
      popupOpen.value = true;
    };

    const closePopup = () => {
      popupOpen.value = false;
    };

    const openRankingModal = (songId, songTitle, songArtist) => {    
      songSelected.value = songId;
      songSelectedInfo.value = { 
        title: songTitle,
        artist: songArtist
      }
      modalVisible.value = true;   
    };

    const handleCloseModal = () => {
      modalVisible.value = false;
    };

    const handleCloseConfirmModal = () => {
      confirmPromoCodeModal.value = false;
      confirmModalVisible.value = false;
    };

    const handleCloseCantAddSongModal = () => {
      cantAddSongModalVisible.value = false;
    };

    const handleConfirmFreeVote = (isPromoCode) => {
      console.log("ispromocode")
      console.log(isPromoCode)
      if (isPromoCode){
        confirmPromoCodeModal.value = true;
      }
      else{
        confirmModalVisible.value = true;
      }
      handleCloseModal();     
    };

    const handleFreeVote = () => {
      try {
        loungeVotingService.rankingFreeBoost(songSelected.value);
      } catch (error) {
        console.log(error);
      }
      handleConfirmFreeVote();
    };

    const handlePromocode = async (promocode) => {
      try {
        const response = await loungeVotingService.applyPromocode(songSelected.value, promocode);
        if (response.success === true){
          handleConfirmFreeVote(true);
        } else if (response.error === "NO_EXIST"){
          titlePromocodeFail.value="No existe"
          messagePromocodeFail.value="El código promocional que has introducido no existe.  Revisa si has puesto alguna letra/número mal."
          promocodeFailedModal.value=true;


        } else if (response.error === "USED_USER"){
          titlePromocodeFail.value="Ya utilizado"
          messagePromocodeFail.value="El código promocional que has introducido ya lo has canjeado antes.  No puedes usarlo más de una vez :(."
          promocodeFailedModal.value=true;

        } else if (response.error === "USED"){
          titlePromocodeFail.value="Ya utilizado"
          messagePromocodeFail.value="El código promocional que has introducido ya ha sido canjeado por alguien antes que tú, lo sentimos :(."
          promocodeFailedModal.value=true;

        } else{
          titlePromocodeFail.value="Error"
          messagePromocodeFail.value="No sabemos qué ha podido pasar, pero algo no ha ido bien, prueba otra vez dentro de un rato."
          promocodeFailedModal.value=true;

        }
        
      } catch (error) {
        console.log('Error al aplicar el código promocional:', error);
        titlePromocodeFail.value="Error"
        messagePromocodeFail.value="No sabemos qué ha podido pasar, pero algo no ha ido bien, prueba otra vez dentro de un rato."
        promocodeFailedModal.value=true;
      }      
    };

    const handleBoost = () => {
      modalVisible.value = false;
      boostModalVisible.value = true;
    };

    const handleCloseBoostModal = () => {
      boostModalVisible.value = false;
    };

    const handleClosePromocodeFailedModal = () => {
      promocodeFailedModal.value = false;
    };

    const handleAddSong = () => {
      if (!timerIsNull.value && timeLeft.value < 60) {
        cantAddSongModalVisible.value = true;
      }
      else{
        router.push(`/lounge/${loungeId}/add-song`)
      }
    };

    const handlePayment = (votePlanPrice) => {
      router.push(`/lounge/${loungeId}/song/${songSelected.value}/pay/${votePlanPrice}/ranking`);
    };

    const { votePlans } = useVotePlans();

    return {
      lens,
      logo,
      info,
      trophy1,
      trophy2,
      trophy3,
      user,
      defaultSongImage,
      rankingSongs,
      openPopup,
      closePopup,
      popupOpen,
      openRankingModal,
      handleCloseModal,
      handleCloseConfirmModal,
      modalVisible,
      confirmModalVisible,
      handleFreeVote,
      handleBoost,
      boostModalVisible,
      handleCloseBoostModal,
      handleAddSong,
      handlePayment,
      songSelected,
      songSelectedInfo,
      details,
      detailsLoaded,
      timeLeft,
      availableFreeBoost,
      votePlans,
      timerIsNull,
      handleCloseCantAddSongModal,
      cantAddSongModalVisible,
      additionalRankingSongs,
      handlePromocode,
      confirmPromoCodeModal,
      messagePromocodeFail,
      titlePromocodeFail,
      promocodeFailedModal,
      delayedFreeBoost,
      handleClosePromocodeFailedModal,
      paragraphs: [
        '¿Ves alguna canción que quieras escuchar? ¡Toca sobre ella y vótala para que se ponga arriba en el ranking!',
        '¿Quieres escuchar una que no está en el ranking? Toca sobre tu canción y añádela al ranking, ¡así la gente podrá votarla!',
        '¡La canción que vaya en primer lugar cuando se acabe la cuenta atrás será la que suene!',
        '¡No te preocupes! Si tu canción no gana algún ranking se te devolverá el dinero.'
      ]
    };
  }
};
</script>

<style scoped>
.yellow-shadow {
  box-shadow: 1px 1px 20px #f9f971 !important;
}
</style>